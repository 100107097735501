/* You can add global styles to this file, and also import other style files */

//Volvo Fonts
@font-face {
    font-family: VolvoBroad;
    src: url(assets/fonts/VolvoBroadPro.otf) format("opentype");
}

@font-face {
    font-family: VolvoNovum;
    src: url(assets/fonts/VolvoNovum-Regular.otf) format("opentype");
}

@font-face {
    font-family: VolvoNovumBold;
    src: url(assets/fonts/VolvoNovum-Medium.otf) format("opentype");
}

//Icons font
@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    src: url(assets/fonts/MaterialSymbolsOutlined.woff2) format('woff2');
}

.mat-icon,
.material-symbols-outlined {
    font-family: 'Material Symbols Outlined', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    &.small{
        height: 16px;
        width: 16px;
        font-size: 16px;
    }

    &.medium{
        height: 20px;
        width: 20px;
        font-size: 20px;
    }
}


html, body { height: 100%;/*height: 100vh; overflow: hidden;*/ }
body { margin: 0; font: 100 14px / 20px VolvoNovum, sans-serif; }

//Text
@black: #333333;
@white: #f7faff;
@secondaryText:#326aa9;

//Background
@primary:#f7faff;
@secondary: #9fbee1;
@tertiary: #e27a00;
@grey: #a6aaaf;
@lightgrey:#dfdfdf;
@page-bg:#dfdfdf;

//Feedback
@red:#D2222D;
@green:#238823;

//Shadow
@shadow:0px 0px 15px #96a1b3;

//Responsive size
@tablet:1100px;
@phone:600px;


//ScrollBar
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-thumb {
    background-color: @secondary;
    border-radius: 20px;
    border: 3px solid @primary;
}

*::-webkit-scrollbar-track {
    background: @primary;
}

*::-webkit-scrollbar-corner {
    background-color: @primary;
}

@media only screen and (min-width: @tablet){
    .mat-mdc-select-panel{
        max-height: 40vh !important;
    }

    .mat-mdc-option{
        min-height: 36px !important;
        height: 36px !important;
    }
}

.cdk-overlay-pane{
    margin: 4px 0 0 -7px;
}

.mat-mdc-select-panel{
    padding: 0 0 !important;
}

mat-option{
    font-family: VolvoNovum, sans-serif;

    &.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled){
        background-color: fade(@secondary, 60%);
    }

    &.mat-mdc-option.mdc-list-item--selected:not(.mat-option-multiple){
        background-color: @secondary !important;
    }

    &.mat-mdc-option.mat-mdc-option-active {
        background-color: fade(@secondary, 20%);
    }

    &.mat-mdc-option.disabled{
        display: none;
    }

    mat-pseudo-checkbox{
        display: none !important;
    }

    .mdc-list-item__primary-text{
        color: @black !important;
    }

    &.small-width{
        padding: 0 8px;
    }
}

ng-select{
    ng-dropdown-panel{
        //min-width: 112px;
        //max-width: 280px;
        overflow: auto;
        //-webkit-overflow-scrolling: touch;
        //padding-top: 0;
        //padding-bottom: 0;
        max-height: 256px;
        //min-width: 100%;
        border-radius: 4px;
        outline: 0;
        background: white;
        box-shadow: @shadow;
        left: 0;
        top: 30px;
    }

    .ng-option{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        //display: block;
        line-height: 3em;
        height: 3em;
        padding: 0 16px;
        text-align: left;
        text-decoration: none;
        //max-width: 100%;
        position: relative;
        cursor: pointer;
        outline: none;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        box-sizing: border-box;
        align-items: center;
        -webkit-tap-highlight-color: rgba(0,0,0,0);

        &:hover{
            background-color: fade(@secondary, 60%);
        }

        &.ng-option-selected{
            background-color: @secondary;
        }
    }

    .ng-option-label {
        display: inline-block;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

button {
    all: unset;
    border-radius: 5px;
    width: max-content;
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    line-height: 24px;
    font-family: "VolvoNovum", sans-serif;
    cursor: pointer;

    svg,
    mat-icon{
        margin: 0 8px 0 0;
    }

    svg.mat-datepicker-toggle-default-icon{
        margin: 0 0 0 0;
    }

    &.small{
        padding: 2px 17px;
        line-height:20px;
    }

    &:hover:enabled{
        transform: scale(1.05);
        box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
    }

    &:focus-visible {
        border: 2px solid black;
    }

    &.secondary{
        background-color: @secondary;
        color: @black;

        &:disabled, &[disabled], &[disable]{
            background-color: fade(@secondary, 50%);
            color: @primary;
            cursor: not-allowed;
        }
    }
    
    &.tertiary{
        background-color: @tertiary;
        color: @white;

        svg path{fill:@white;}
        mat-icon{color: @white;}

        &:disabled, &[disabled], &[disable]{
            background-color: fade(@tertiary, 50%);
            color: @primary;
            cursor: not-allowed;
        }
    }

    &.reverse-tertiary{
        background-color: transparent;
        color: @black;
        border: 1px solid @tertiary;

        &:disabled, &[disabled], &[disable]{
            border: 1px solid fade(@tertiary, 50%);
            color: fade(@black, 50%);
            cursor: not-allowed;
        }

        &:hover:not([disabled]){
            background-color: @tertiary;
            color: @primary;
        }
    }
    
    &.grey{
        background-color: @grey;
        color: @black;

        &:disabled, &[disabled], &[disable]{
            background-color: fade(@grey, 50%);
            color: @primary;
            cursor: not-allowed;
        }
    }    
}

mat-tree-node{
    button{
        padding: 8px;
        border-radius: 100%;

        &:hover,
        &:hover:enabled{
            background: fade(@secondary, 40%);
            box-shadow: none;
            transform: scale(1);
        }
    }
}

input:disabled,
textarea:disabled,
.ng-select-disabled{
 color: rgba(0, 0, 0, 0.38);
 cursor: not-allowed;
}

.mat-calendar-body-cell{
    padding: 0 !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color:fade(@secondary, 50%);
}

.tooltip-container{
    position: relative;
}

.tooltip-content{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: @primary;
    min-width: 220px;
    box-shadow:@shadow;
    z-index: 1;
    border-radius: 5px;
    color: @black;
    padding: 10px;
    transition: all 0.2s ease-in-out;

    &.display-top{
        bottom: 100%;
    }

    &.display-right{
        left: 100%;
        transform: translate(0, -40px);
    }

    &.display-left{
        right: 100%;
        transform: translate(0, -40px);
    }
}

.tooltip-container{
    
    &:hover .tooltip-content{
        opacity: 1;
        visibility: visible;
    }

    &.relative{
        position: relative;
    }
}

.text-too-long-tooltip {
    position: fixed;
    left: 20%;
    background-color: @primary;
    color: @black;
    box-shadow: @shadow;
    padding: 5px;
    border-radius: 5px;
    z-index: 9999;
}

//CheckBox
input[type=checkbox] {
    position: relative;
    cursor: pointer;
    width: 24px;
    height: 20px;
    margin: 1px 1px;

    &.small{
        &:before{
            width: 10px;
            height: 10px;
            top:-12px;
            left: 0;
        }

        &:checked:after {
            width: 3px;
            height: 6px;
            border-width: 0px 2px 2px 0;
            top: -10px;
            left: 5px;
        }
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 20px;
        height: 20px;
        top: -1px;
        left: -1px;
        border: 2px solid @secondary;
        border-radius: 3px;
        background-color: @primary;
    }

    &:checked:before {
        background-color: @secondary;
    }

    &:checked:after {
        content: "";
        display: block;
        width: 6px;
        height: 13px;
        border: solid @primary;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 1px;
        left: 6px;
    }
}

.mdc-switch:enabled .mdc-switch__track::after{
    background-color: @secondary !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
    background-color: @secondary !important;   
}

.mat-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
    background-color: @secondary;
}

.mat-slide-toggle.mat-mdc-slide-toggle-checkedd:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: @secondary;
}

.mat-slide-toggle.mat-mdc-slide-toggle-checked .mat-ripple-element {
    background-color: @tertiary;
}

mat-slide-toggle input[type=checkbox] {
    position: auto;
    &:before, &:after{
        display: none !important;
    }
}

//SPINNER
mat-progress-spinner circle, mat-spinner circle {
    stroke: @secondary;
}
mat-progress-spinner.primary circle, mat-spinner.primary circle {
    stroke: @primary;
}

//LOCATION SELECTOR
.location-zone-container *{
    fill:fade(@secondary, 0%);
    transition: all 0.2s ease-in-out;

    &.selected{
        fill:fade(@secondary, 40%);
    }
}

.location-zone-container:hover *{
    fill:fade(@secondary, 40%);
    cursor: pointer;
}